<!--
 * @Description: 开票记录 invoiceHistory
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-06-07 11:32:29
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="开票记录"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="main">
      <van-row class="list"
               v-for="(item, index) in invoiceRecord"
               :label="item.name"
               :value="item.code"
               :key="index"
               @click="handleClickItem(item)">
        <van-col :span="1"></van-col>
        <van-col :span="17">
          <van-row style="margin-top:10px;font-size:15px;color: #333333;">
            <van-image width="14"
                       height="14"
                       style="padding-top:2px"
                       :src="require('@/assets/wxInvoice/shijian.png')" />
            <span style="margin-left:5px">{{item.invoiceDate}}</span>
            <!-- <van-tag plain
                     color="#19A9FC"
                     style="margin-left:3.2%">
              已开票
            </van-tag> -->
          </van-row>
          <van-row style="margin-top:7px;font-size: 15px;color: #666666;">
            停车服务
          </van-row>
        </van-col>
        <van-col :span="5"
                 style="margin-top:20px">
          <span style="font-weight: bold;font-size: 21px;color: #333333;">
            {{item.money}}
          </span>
          <span style="font-size: 15px;color: #666666;">
            元
          </span>
        </van-col>
        <van-col :span="1"
                 style="margin-top:25px">
          <van-image width="8"
                     height="14"
                     :src="require('@/assets/wxInvoice/arrow.png')" />
        </van-col>
      </van-row>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      invoiceRecord: [] // 开票记录
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryInvoiceRecord()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 获取开票历史
    queryInvoiceRecord () {
      this.$invoiceApi.queryInvoiceRecord({}).then(res => {
        this.invoiceRecord = res.resultEntity
      })
    },
    // 点击查看开票详情
    handleClickItem (item) {
      // 有pdf就直接进入没pdf就给他刷新一下在进入
      if (!item.pdfUrl) {
        const info = {
          fpqqlsh: item.invoiceSequence
        }
        this.$invoiceApi.getInvoiceAgain(info).then(() => {
        })
      }
      this.$router.push({
        name: 'invoiceDetails',
        query: { invoiceList: JSON.stringify(item) }
      })
    },
    // 返回上一页
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .main {
    margin-top: 12px;
    .list {
      height: 64px;
      margin-top: 5px;
      background-color: #ffffff;
    }
  }
}
</style>
